import React, { Component } from "react";
import PropTypes from 'prop-types';
import './FlightTextInput.scss';
import SvgRemove24 from '../../icons/Remove24';
import SvgError24 from '../../icons/Error24';

class FlightTextInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: props.value || '',
    };

    this.wrapperRef = (element) => {
      if (element) {
        // Resize component width based on this.props.width
        /* eslint-disable no-param-reassign */
        element.style.width = props.width;
      }
    };
  }

  handleValueChange = (event) => {
    const { onChange } = this.props;
    this.setState({
      inputValue: event.target.value,
    });
    if (onChange) {
      onChange(event.target.value);
    }
  }

  handleTrailingIconClear = () => {
    this.setState({
      inputValue: '',
    });
  }

  render() {
    const {
      iconInput,
      disabled,
      hasError,
      hasClearIcon,
      placeholderText,
      labelText,
      errorMessage,
      className,
    } = this.props;
    const { inputValue } = this.state;
    const mainClass = 'flight-text-input';

    let labelWrapperClass = mainClass;
    labelWrapperClass += iconInput ? ` ${mainClass}--with-icon-input` : '';
    if (disabled) {
      labelWrapperClass += ` ${mainClass}--disabled`;
    } else if (hasError) {
      labelWrapperClass += ` ${mainClass}--error`;
    }
    labelWrapperClass += className ? ` ${className}` : '';

    let inputClass = `${mainClass}__input`;
    inputClass += inputValue && hasClearIcon ? ` ${inputClass}--with-clear-icon` : '';

    let iconInputTrailingClass = `${mainClass}__icon-trailing`
    iconInputTrailingClass += (hasError && (!inputValue || !hasClearIcon) ?
      ` ${mainClass}__icon-trailing--error` : '');

    let errorMessageClass = `${mainClass}__error-message`;
    errorMessageClass += hasError ? ` ${errorMessageClass}--show` : '';
    
    let iconInputTrailing = null;
    if (hasClearIcon && inputValue.length > 0 && !disabled) {
      iconInputTrailing = (
        <SvgRemove24
          className={iconInputTrailingClass}
          onClick={this.handleTrailingIconClear}
        />);
    } else if (hasError) {
      iconInputTrailing = (
        <SvgError24
          className={iconInputTrailingClass}
        />);
    }

    return (
      <div className="flight-text-input-wrapper" ref={this.wrapperRef}>
        <label className={labelWrapperClass} htmlFor="text-input">
          {
            iconInput && (
              <div className={`${mainClass}__icon-input`}>
                {iconInput}
              </div>)
          }
          {iconInputTrailing}
          <input
            className={inputClass}
            type="text"
            placeholder={placeholderText}
            value={inputValue}
            onChange={this.handleValueChange}
          />
          <span className={`${mainClass}__label`}>{labelText || 'Label'}</span>
          <span className={`${mainClass}__border`} />
        </label>
        <span className={errorMessageClass}>{errorMessage || 'Error field'}</span>
      </div>
    );
  }
};

FlightTextInput.propTypes = {
  labelText: PropTypes.string,
  width: PropTypes.string,
  onChange: PropTypes.func,
  iconInput: PropTypes.element,
  hasError: PropTypes.bool,
  hasClearIcon: PropTypes.bool,
  placeholderText: PropTypes.string,
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string,
};

FlightTextInput.defaultProps = {
  labelText: '',
  width: '232px',
  onChange: null,
  iconInput: '',
  hasError: false,
  hasClearIcon: false,
  placeholderText: '',
  className: '',
  errorMessage: null,
  disabled: false,
  value: '',
};

export default FlightTextInput;
