import React from 'react';
import PropTypes from 'prop-types';
import './EmptyState.scss';

const EmptyState = ({children, src}) => {
  const mainClass = "empty-state";

  return (
    <div className={mainClass}>
      <img
        src={src}
        className={`${mainClass}__img`}
        alt="empty"
      />
      <div className={`${mainClass}__text`}>{children}</div>
    </div>
  )
};

EmptyState.propTypes = {
  children: PropTypes.element,
  src: PropTypes.string.isRequired,
};

EmptyState.defaultProps = {
  children: null,
};

export default EmptyState;