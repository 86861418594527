import React, { Component } from "react";
import PropTypes from 'prop-types';
import './FlightButton.scss';
import SvgLoading24 from '../../icons/Loading24';

class FlightButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  handleClick = () => {
    const { disabled, onClick, loading } = this.props;
    const { isLoading } = this.state;
    if (disabled) return;
    if (onClick) {
      onClick();
    }
    
    if (!isLoading && loading) {
      this.setState({ isLoading: true });
    }
  }

  render() {
    const { type, size, iconLeft, iconRight, label, loading, className, disabled } = this.props;
    const { isLoading } = this.state;
    const mainClass = 'flight-btn';
    const labelClass = `${mainClass}-label`;
    const labelElement = label ? <span className={labelClass}>{label}</span> : null;
    const loadingIcon = (loading && isLoading) ? <SvgLoading24 /> : null;

    let buttonClass = mainClass;
    buttonClass += ` ${mainClass}--${type || 'primary'}`;
    buttonClass += ` ${mainClass}--${size || 'medium'}`;
    buttonClass += (iconLeft || iconRight) ?
    ` ${mainClass}--with-icon` : '';
    buttonClass += !label ? ` ${mainClass}--without-text` : '';
    buttonClass += isLoading ? ` ${mainClass}--loading` : '';
    buttonClass += className ? ` ${className}` : '';

    return (
      <button type="button" className={buttonClass} onClick={this.handleClick} disabled={disabled}>
        {iconLeft}
        {labelElement}
        {iconRight}
        {loadingIcon}
      </button>
    );
  }
};

FlightButton.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
  iconLeft: PropTypes.element,
  iconRight: PropTypes.element,
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

FlightButton.defaultProps = {
  label: '',
  type: 'primary',
  size: 'medium',
  iconLeft: null,
  iconRight: null,
  loading: false,
  className: '',
  disabled: false,
};

export default FlightButton;
