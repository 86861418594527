/* eslint-disable react/prop-types */
import React from 'react';
import './Card.scss'

const Card = ({title, children}) => {
  return (
    <div className="card">
      <div className="card-header">
        {title}
      </div>
      <div className="card-body">
        {children}
      </div>
    </div>
  )
}

export default Card;
