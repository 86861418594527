import React from 'react';
import PropTypes from 'prop-types';
import './StatusBar.scss';
import SvgCheckCircle24 from '../../icons/CheckCircle24';
import SvgError24 from '../../icons/Error24';
import SvgInfoStroke24 from '../../icons/InfoStroke24';


const statusTypeMap = {
  success: 'success',
  error: 'error',
  info: 'info',
};

const StatusBar = (props) => {
  const { label, type } = props;
  const mainClass = 'status-bar';
  const statusType = statusTypeMap[type] ? statusTypeMap[type] : statusTypeMap.info;
  const statusIconClass = `${mainClass}__status-icon ${mainClass}__status-icon--${statusType}`;
  const statusToIconMap = {
    success: <SvgCheckCircle24 className={statusIconClass} />,
    error: <SvgError24 className={statusIconClass} />,
    info: <SvgInfoStroke24 className={statusIconClass} />,
  };

  return (
    <span className={mainClass}>
      {label}
      {statusToIconMap[statusType]}
    </span>
  );
};

StatusBar.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
}

StatusBar.defaultProps = {
  label: '',
  type: 'info',
}

export default StatusBar;
