import React from 'react';
import PropTypes from 'prop-types';
import TableListItem from '../table-list-item/TableListItem';
import TableHeader from '../table-header/TableHeader';
import TableLoading from '../table-loading/TableLoading';
import EmptyState from '../empty-state/EmptyState';
import { DESIGN_SYSTEM } from '../../../../constants';
import './TableList.scss';

const TableList = (props) => {
  const { tableHeaders, tableData, emptyStateMessage,
    emptyStateImg, isLoading, loadingRowNumber, className } = props;
  let tableRows = null;
  if (isLoading) {
    tableRows = (
      <TableLoading
        rowNumber={loadingRowNumber}
        colNumber={tableHeaders.filter(header => header.isVisible).length}
      />
    );
  }
  else if (tableData.length > 0) {
    tableRows = tableData.map(item => {
      return (
        <TableListItem
          item={item}
          columns={tableHeaders.filter(header => header.isVisible).map(col => col.key)}
          key={item.Key}
        />);
    });
  }

  return (
    <div className="table-list-container">
      <div className="table-list">
        <div className="table-responsive">
          <table className={`table ${className}`}>
            <thead>
              <tr>
                {
                  tableHeaders.map((header) => {
                    return <TableHeader header={header} key={header.key} />
                  })
                }
              </tr>
            </thead>
            <tbody>{tableRows}</tbody>
          </table>
          {!tableData.length && !isLoading && (
            <EmptyState src={`assets/${DESIGN_SYSTEM.EMPTY_STATES[emptyStateImg]}.svg`}>
              {emptyStateMessage}
            </EmptyState>
            )
          }
        </div>
      </div>
    </div>
  );
};

TableList.propTypes = {
  className: PropTypes.string,
  emptyStateMessage: PropTypes.element,
  emptyStateImg: PropTypes.string,
  tableHeaders: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    key: PropTypes.string,
  })).isRequired,
  tableData: PropTypes.arrayOf(PropTypes.shape({
    Name: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
    ]),
  })),
  isLoading: PropTypes.bool,
  loadingRowNumber: PropTypes.number,
}

TableList.defaultProps = {
  className: '',
  emptyStateMessage: null,
  emptyStateImg: '',
  tableData: [],
  isLoading: false,
  loadingRowNumber: 3,
}

export default TableList;
