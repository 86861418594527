import React from 'react';
import PropTypes from 'prop-types';
import './TableListItem.scss';

const TableListItem = props => {
  const { item, columns } = props;
  return (
    <tr className="table-item">
      {
        columns.map(col => {
          return <td className="table-item-cell" key={col}>{item[col]}</td>;
        })
      }
    </tr>
  );
};

TableListItem.propTypes = {
  item: PropTypes.shape({
    Key: PropTypes.string,
    Name: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
    ]),
  }).isRequired,
  columns: PropTypes.arrayOf(PropTypes.string),
};

TableListItem.defaultProps = {
  columns: [],
};

export default TableListItem;
