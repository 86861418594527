import React, { Component } from "react";
import PropTypes from 'prop-types';
import './FlightTextArea.scss';
import SvgError24 from '../../icons/Error24';

const DEFAULT_MAIN_CLASS = 'flight-text-area'
const DEFAULT_ERROR_MESSAGE = 'Error field';
const DEFAULT_MAX_LENGTH = 500;
const DEFAULT_WIDTH = '400px';
const DEFAULT_LABEL_TEXT = 'Description';

class FlightTextArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: props.value || '',
    };

    this.wrapperRef = (element) => {
      if (element) {
        // Resize component width based on this.props.width
        /* eslint-disable no-param-reassign */
        element.style.width = props.width;
      }
    };
  }

  handleValueChange = (event) => {
    const { onChange } = this.props;

    this.setState({
      inputValue: event.target.value,
    });
    if (onChange) {
      onChange(event.target.value);
    }
  }

  render() {
    const { errorMessage, maxLength, hasError, disabled, labelText, className } = this.props;
    const { inputValue } = this.state;
    const iconStatus = hasError ? (
      <SvgError24 className={`${DEFAULT_MAIN_CLASS}__status-icon`} />) : null;
    const footerText = (hasError ?
      errorMessage : `${inputValue.length} / ${maxLength}`);

    let mainClass = DEFAULT_MAIN_CLASS;
    if (hasError) {
      mainClass += ` ${mainClass}--error`;
    } else if (disabled) {
      mainClass += ` ${mainClass}--disabled`;
    }
    mainClass += className ? ` ${className}` : '';

    return (
      <div className={mainClass} ref={this.wrapperRef}>
        <textarea
          className={`${DEFAULT_MAIN_CLASS}__textarea`}
          maxLength={maxLength}
          value={inputValue}
          onChange={this.handleValueChange}
         />
        <span className={`${DEFAULT_MAIN_CLASS}__span`}>{labelText}</span>
        {iconStatus}
        <span className={`${DEFAULT_MAIN_CLASS}__footer`}>{footerText}</span>
      </div>
    );
  }
};

FlightTextArea.propTypes = {
  labelText: PropTypes.string,
  width: PropTypes.string,
  onChange: PropTypes.func,
  hasError: PropTypes.bool,
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  maxLength: PropTypes.number,
};

FlightTextArea.defaultProps = {
  labelText: DEFAULT_LABEL_TEXT,
  width: DEFAULT_WIDTH,
  onChange: null,
  hasError: false,
  className: '',
  errorMessage: DEFAULT_ERROR_MESSAGE,
  disabled: false,
  value: '',
  maxLength: DEFAULT_MAX_LENGTH,
};

export default FlightTextArea;
