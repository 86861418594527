/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types';
import getRecommendations from '../actionCreators';
import './recommendationsContainer.scss';
import FlightButton from '../../../shared/components/flight-button/FlightButton';
import TableList from '../../../shared/components/table-list/TableList';

class RecommendationsContainer extends Component {
  constructor(props) {
    super(props);
    this.tableHeaders = [{
      name: '',
      value: 1,
      key: 'id',
      isVisible: false,
    }, {
      name: 'Name',
      value: 1,
      key: 'Name',
      isVisible: true,
    }, {
      name: 'Date Created',
      value: 1,
      key: 'CreatedAt',
      isVisible: true,
    }, {
      name: ' ',
      value: 1,
      key: 'Action',
      isVisible: true,
    }];
  }

  handleEditRedirect = () => {
    const { history } = this.props;
    history.push('/recommendations/edit');
  }

  handleGetResult = () => { // add rec into arg to get result of recommendation
    // TODO: download recommendation file
    const { history } = this.props;
    history.push('/recommendations/result');
  }

  render() {
    const { recommendations, isLoading, recommendationError } = this.props;
    if (recommendationError.length > 0) {
      return <div>{recommendationError}</div>
    }

    const emptyStateMessage = (
      <span>No recommendations have been created yet. To create a recommendation,
      first go to <strong>Data Hub</strong> and upload at least one file
      containing customer transaction data, which will be used for model training.
      The model must be trained first before recommendations can be created.
      </span>
    );
    const tableData = recommendations.map(rec => {
      let getResult = null;
      switch (rec.Status) {
        case 'Completed':
          getResult = (
            <FlightButton
              type="secondary"
              size="small"
              label="Get Results"
              className="recommendations-result"
              onClick={() => this.handleGetResult(rec)}
            />);
          break;
        case 'Failed':
          getResult = <span className="recommendations-result--failed">Failed</span>;
          break;
        default:
          getResult = <span className="recommendations-result--processing">Processing...</span>;
          break;
      }
      return { ...rec, Action: getResult,};
    });

    return (
      <div className="recommendation-container">            
        <div className="recommendation-container__button-group">
          <FlightButton
            label='New Recommendations'
            onClick={this.handleEditRedirect}
          />
        </div>
        <div className="recommendation-container__title">Past recommendations</div>
        <TableList
          tableHeaders={this.tableHeaders}
          emptyStateMessage={emptyStateMessage}
          emptyStateImg='recommendationEmpty'
          isLoading={isLoading}
          tableData={tableData}
        />
      </div> 
    );
  }
}

RecommendationsContainer.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  // from mapStateToProps
  isLoading: PropTypes.bool.isRequired,
  recommendations: PropTypes.arrayOf(PropTypes.shape({
    Name: PropTypes.string,
    CreatedAt: PropTypes.string,
  })).isRequired,
  recommendationError: PropTypes.string,
};

RecommendationsContainer.defaultProps = {
  recommendationError: '',
}

const mapStateToProps = (state) => {
  return {
    recommendations: state.recommendationsReducer.recommendationsList,
    isLoading: state.recommendationsReducer.isRecommendationsLoading,
    recommendationError: state.recommendationsReducer.recommendationError,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onGetRecommendations: dispatch(getRecommendations()),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RecommendationsContainer));
