import React from 'react';

const SvgProfileDefault = props => (
  <svg width={40} height={40} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 0c11.046 0 20 8.954 20 20s-8.954 20-20 20S0 31.046 0 20 8.954 0 20 0zm.178 11.85a5.974 5.974 0 110 11.949 5.974 5.974 0 010-11.949zm-.195 13.117c5.576 0 10.428 3.095 12.932 7.662a19.788 19.788 0 01-12.88 4.741 19.789 19.789 0 01-12.95-4.8c2.513-4.534 7.346-7.604 12.898-7.604z"
      fill="#051336"
      fillOpacity={0.5}
    />
  </svg>
);

export default SvgProfileDefault;
