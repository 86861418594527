import * as types from './types';
import Session from '../../shared/services/session';
import { NESTOR } from '../../../constants';
import axios from  '../../../utils/axios';

const getDatasetsLoading = (payload) => {
    return {
        type: types.DATA_HUB_DATASETS_LOADING,
        payload
    }
};

const getDatasetsSuccess = (payload) => {
    return {
        type: types.DATA_HUB_DATASETS_SUCCESS,
        payload
    }
};

const getDatasetsError = (payload) => {
    return {
        type: types.DATA_HUB_DATASETS_ERROR,
        payload
    }
};

const fetchDatasets = () => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({
                data: [
                          {
                              Key: '1',
                              Name: 'mastercard-grocery-transactions-10-2019.csv',
                              Size: '841 MB',
                              CreatedAt: 'Nov 3, 2019',
                              ModifiedAt: 'Nov 3, 2019',
                              Status: 'Trained',
                              DownloadLink: '',
                          },
                          {
                              Key: '2',
                              Name: 'mastercard-grocery-transactions-09-2019.csv',
                              Size: '864 MB',
                              CreatedAt: 'Oct 1, 2019',
                              ModifiedAt: 'Oct 1, 2019',
                              Status: 'Trained',
                              DownloadLink: '',
                          },
                          {
                              Key: '3',
                              Name: 'mastercard-grocery-transactions-08-2019.csv',
                              Size: '833 MB',
                              CreatedAt: 'Sep 2, 2019',
                              ModifiedAt: 'Sep 2, 2019',
                              Status: 'Trained',
                              DownloadLink: '',
                          },
                          {
                              Key: '4',
                              Name: 'mastercard-grocery-transactions-07-2019.csv',
                              Size: '812 MB',
                              CreatedAt: 'Aug 2, 2019',
                              ModifiedAt: 'Aug 2, 2019',
                              Status: 'Trained',
                              DownloadLink: '',
                          },
                          {
                              Key: '5',
                              Name: 'mastercard-grocery-transactions-06-2019.csv',
                              Size: '837 MB',
                              CreatedAt: 'Jul 1, 2019',
                              ModifiedAt: 'Jul 1, 2019',
                              Status: 'Trained',
                              DownloadLink: '',
                          },
                          {
                              Key: '6',
                              Name: 'mastercard-grocery-transactions-05-2019.csv',
                              Size: '785 MB',
                              CreatedAt: 'Jun 1, 2019',
                              ModifiedAt: 'Jun 1, 2019',
                              Status: 'Trained',
                              DownloadLink: '',
                          },
                          {
                              Key: '7',
                              Name: 'mastercard-grocery-transactions-04-2019.csv',
                              Size: '867 MB',
                              CreatedAt: 'May 2, 2019',
                              ModifiedAt: 'May 2, 2019',
                              Status: 'Trained',
                              DownloadLink: '',
                          },
                          {
                              Key: '8',
                              Name: 'mastercard-grocery-transactions-03-2019.csv',
                              Size: '743 MB',
                              CreatedAt: 'Apr 3, 2019',
                              ModifiedAt: 'Apr 3, 2019',
                              Status: 'Trained',
                              DownloadLink: '',
                          },
                          {
                              Key: '9',
                              Name: 'mastercard-grocery-transactions-02-2019.csv',
                              Size: '625 MB',
                              CreatedAt: 'Mar 1, 2019',
                              ModifiedAt: 'Mar 1, 2019',
                              Status: 'Trained',
                              DownloadLink: '',
                          },
                          {
                              Key: '10',
                              Name: 'mastercard-grocery-transactions-01-2019.csv',
                              Size: '713 MB',
                              CreatedAt: 'Feb 2, 2019',
                              ModifiedAt: 'Feb 2, 2019',
                              Status: 'Trained',
                              DownloadLink: '',
                          },
                          {
                              Key: '11',
                              Name: 'mastercard-grocery-transactions-12-2018.csv',
                              Size: '978 MB',
                              CreatedAt: 'Jan 3, 2019',
                              ModifiedAt: 'Jan 3, 2019',
                              Status: 'Trained',
                              DownloadLink: '',
                          },
                          {
                              Key: '12',
                              Name: 'mastercard-grocery-transactions-11-2018.csv',
                              Size: '814 MB',
                              CreatedAt: 'Dec 1, 2018',
                              ModifiedAt: 'Dec 1, 2018',
                              Status: 'Trained',
                              DownloadLink: '',
                          }
                ]
            })
        }, 1000);
    });
};

const getDatasets = () => (dispatch) => {
    const apiCall = fetchDatasets();
    let isLoading = true;

    dispatch(getDatasetsLoading(isLoading));
    apiCall
    .then(res => {
      return res;
    })
    .then(response => {
        isLoading = false;
        dispatch(getDatasetsLoading(isLoading));
        dispatch(getDatasetsSuccess(response.data));
    })
    .catch(err => {
        isLoading = false;
        dispatch(getDatasetsLoading(isLoading));
        dispatch(getDatasetsError(err))
    });
};

const delay = (duration) => new Promise((resolve) => {
  setTimeout(() => {
      resolve();
  }, duration)
});

const sendRequest = async (file) => {
  const contentType = file.type || 'application/octet-stream';
  const session = new Session();
  const nestorDataHost = NESTOR.DATA_HOST;
  const fileName = `transaction_data/${file.name}`;

  let response = await axios.get(`${nestorDataHost}/s3/presigned`, {
    headers: {
      Authorization: session.getToken(),
    },
    params: {
      name: fileName,
      contentType,
    }
  });

  const { url } = response.data;

  try {
    response = await axios.put(url, file, {
      headers: {
        'Content-Type': contentType,
      }
    });

    if (response) {
      // TODO: add dispatch when get response from server
    }
  } catch { // add (e) in arg
    // if (error) {
    //     this.setState({
    //         error,
    //     })
    // }
  }
}

const uploadFiles = files => async () => {
  try {
    await Promise.all([
      ...files.map(f => sendRequest(f)),
      delay(1000)
    ]);
  } catch { // add (e) in arg
    // console.log(e);
  }
}

export { getDatasets, uploadFiles };
