/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { getResults, uploadFiles } from '../actionCreators';
import Card from '../../../shared/components/card/Card';
import CardTitle from '../../../shared/components/cardTitle/CardTitle';
import Modal from '../../../shared/components/modal/Modal';
import FlightButton from '../../../shared/components/flight-button/FlightButton';

import './recommendationsEditorContainer.scss';

class RecommendationsEditorContainer extends Component {
  constructor(props) {
    super(props);

    this.fileInputProductRef = React.createRef();
    this.fileInputCustomerRef = React.createRef();
    this.fileListToArray = this.fileListToArray.bind(this);
    this.validate = this.validate.bind(this);

    this.state = {
      name: '',
      productsFile: null,
      customersFile: null,
      numberOfProducts: 0,
      isCancel: false,
      isDisabled: true,
      invalidInputs: [{
        name: 'numberOfProducts',
        value: 'invalid',
      }, {
        name: 'name',
        value: 'invalid',
      }],
    }
  }

  handleValueChange = (e) => {
    const value =  e.target ? e.target.value : 0;
    this.handleValidation(value, 'numberOfProducts');

    this.setState(() => ({
      numberOfProducts: value,
    }));
  }

  onFileAdded = (evt, fileType) => {
    const { files }  = evt.target;
    const { onUploadFile } = this.props;
    const updatedFiles = [];
    const entries = this.transformFileName(files);

    for (const entry of entries) {
      updatedFiles.push(entry[1]);
    }
    
    const array = this.fileListToArray(updatedFiles);

    this.setState(() => ({
      [fileType]: array[0],
    }));

    onUploadFile(array[0], fileType);
  }

  // eslint-disable-next-line class-methods-use-this
  fileListToArray = (list) => {
    const array = []
    for (let i = 0; i < list.length; i+=1) {
      array.push(list[i])
    }
    return array
  }

  handleTitleChange = (name) => {
    this.handleValidation(name, 'name');
    this.setState(() => ({
      name,
    }));
  }

  handleCancel = () => {
    this.setState({
      isCancel: true,
    });
  }

  handleCloseModal = () => {
    this.setState({
      isCancel: false,
    });
  }

  handleContinueModal = () => {
    const { history } = this.props;
    history.push("/recommendations");
  }

  handleGetResults = () => {
    const { onGetResults } = this.props;
    const { name, numberOfProducts } = this.state;
    const data = {
      name,
      numberOfProducts,
    };

    if (onGetResults) {
      onGetResults(data);
    }
  }

  transformFileName = (files) => {
    const fd = new FormData();

    for (let i = 0; i < files.length; i++) {
      const now = Date.now();
      let name = files[i].name.split('.');
      const ext = name.pop();

      name = [`${name[0]}-${now + i}`];
      name.push(ext);

      const newFileName = name.join('.');

      fd.append('file[]', files[i], newFileName);
    }

    return fd.entries();
  }

  validate() {
    const { invalidInputs } = this.state;

    if (invalidInputs.length) {
      this.setState(() => ({ isDisabled: true }));
    } else {
      this.setState(() => ({ isDisabled: false }))
    }
  }

  handleValidation(value, name) {
    const { invalidInputs } = this.state;
    if (!value.length) {
      const filteredItems = invalidInputs.filter(item => {
        return item.name === name;
      });

      if (filteredItems.length < 1) {
        invalidInputs.push({
          name,
          value: 'invalid',
        });

        this.setState(() => ({
          invalidInputs,
        }));

        // TODO: clear timeout
        setTimeout(() => {
          this.validate();
        }, 400);
      }
    } else {
      const filteredItems = invalidInputs.filter(item => {
        return item.name !== name;
      });

      this.setState(() => ({
        invalidInputs: filteredItems,
      }));
      // TODO: clear timeout
      setTimeout(() => {
        this.validate();
      }, 400);
    }
  }

  render() {
    const { customersFile, productsFile, isCancel, isDisabled } = this.state;

    return (
      <div className="new-recommendation-container">
        <Modal
          title="Discard recommendation?"
          show={isCancel}
          actionName="Discard"
          onClose={this.handleCloseModal}
          onContinue={this.handleContinueModal}
        >
          <p>By leaving this page, all progress will be lost. Are you sure you want to discard this recommendation?</p>
        </Modal>
        <div className="new-recommendation-container__title">New recommendations</div>
        <div className="card-container">
          <Card title={<CardTitle title="Untitled recommendation" onTitleChange={this.handleTitleChange}/>}>
            <div className="content-box">
              <div className="content-box-container">
                <div className="icon-container">
                  <img src="/assets/icons8-goal3.svg" alt="dataset"/>
                </div>
                <div>
                  <p className="title">Goal</p>
                  <p>Recommend products to customers based on past purchases</p>
                </div>
              </div>
            </div>
          </Card>
        </div>
        <div className="card-container">
          <Card title="Input Data">
            <div className="content-box">
              <div className="content-container">
                <div className="icon-container">
                  <img src="/assets/icons8-black_friday1.svg" alt="dataset"/>
                </div>
                <div className="upload-section">
                  <p>Upload a dataset containing proudcts you want to recommend to customers.</p>
                  <input
                    className="file-input" 
                    ref={ref => {this.fileInputProductRef = ref}}
                    id="upload-product-dataset1"
                    type="file"
                    accept="text/csv"
                    onChange={event => {this.onFileAdded(event, 'productsFile')}}
                  />
                  <FlightButton
                    type="secondary"
                    size="small"
                    label="Upload Dataset"
                    onClick={() => this.fileInputProductRef.click()}
                  />
                  <a href="/assets/files/products-sample.csv" download="products-sample.csv">
                    <FlightButton
                      type="link"
                      label="Download sample file"
                      onClick={null}
                      className="flight-button--download-sample"
                    />
                  </a>
                  {
                    productsFile ? (
                      <div className="file-section">
                        <div className="icon-container">
                          <img src="/assets/check-circle-24.svg" alt="dataset"/>
                        </div>
                        <div className="file-info">
                          <span className="name">{productsFile.name}</span>
                          <span className="size">{productsFile.size}</span>
                        </div>
                      </div>
                    ) : null
                  }
                </div>
              </div>
              <div className="content-container">
                <div className="icon-container">
                  <img src="/assets/icons8-customer_insight1.svg" alt="dataset"/>
                </div>
                <div className="upload-section">
                  <p>Upload a dataset containing your customer list.</p>
                  <input
                    className="file-input" 
                    ref={ref => {this.fileInputCustomerRef = ref}}
                    type="file"
                    id="upload-customer-dataset2"
                    accept="text/csv"
                    onChange={event => { this.onFileAdded(event, 'customersFile')}}
                  />
                  <FlightButton
                    type="secondary"
                    size="small"
                    label="Upload Dataset"
                    onClick={() => this.fileInputCustomerRef.click()}
                  />
                  <a href="/assets/files/customers-sample.csv" download="customers-sample.csv">
                    <FlightButton
                      type="link"
                      label="Download sample file"
                      onClick={null}
                      className="flight-button--download-sample"
                    />
                  </a>
                  {
                    customersFile ?
                    <div className="file-section">
                      <div className="icon-container">
                        <img src="/assets/check-circle-24.svg" alt="dataset"/>
                      </div>
                      <div className="file-info">
                        <span className="name">{customersFile.name}</span>
                        <span className="size">{customersFile.size}</span>
                      </div>
                    </div>: null
                  }
                </div>
              </div>
            </div>
          </Card>
        </div>
        <div className="card-container">
          <Card title="Define Output">
            <div className="content-box">
              <div className="content-box-container">
                <div className="icon-container">
                  <img src="/assets/icons8-keyboard3.svg" alt="keyboard"/>
                </div>
                <div>
                  <h4 className="inner-title">Number of recommendations per customer</h4>
                  <p className="inner-subtitle">Define how many product recommendations you want each customer to receive.</p>
                  <p className="desc">Recommend
                    <input
                      type="number"
                      className="form-control num-of-products"
                      onChange={(e) => {this.handleValueChange(e)}}
                      />
                    products per customer
                  </p>
                </div>
              </div>
            </div>
          </Card>
        </div>
        <div className="footer">
          <FlightButton 
              label="Discard"
              type="minor"
              className="footer__btn--cancel"
              onClick={this.handleCancel}
          /> 
          <FlightButton 
              label='Get Results'
              type="primary"
              disabled={isDisabled}
              className="footer__btn--results"
              onClick={this.handleGetResults}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {}
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetResults: dispatch(getResults),
    onUploadFile: ((file, fileType) => {
      dispatch(uploadFiles(file, fileType));
    }),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RecommendationsEditorContainer));