import * as types from './types';

const initialState = {
  recommendationsList: [],
  isRecommendationsLoading: false,
  recommendationError: '',
};

function recommendationsReducer(state = initialState, action) {
  switch(action.type) {
    case types.RECOMMENDATIONS_LOADING:
      return { ...state, isRecommendationsLoading: action.payload,
        recommendationError: '' };
    case types.RECOMMENDATIONS_SUCCESS:
      return { ...state, isRecommendationsLoading: false,
        recommendationsList: action.payload, recommendationError: '' };
    case types.RECOMMENDATIONS_ERROR:
      return { ...state, isRecommendationsLoading: false,
        recommendationError: action.payload };
    default:
      return state;
  }
}

export default recommendationsReducer;