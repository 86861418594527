import React from 'react';
import './StaticResultContainer.scss';

const StaticResultContainer = () => {
  return (
    <div>
      <img src="/assets/result-page.svg" alt="result"/>
    </div>
  )
};

export default StaticResultContainer;
