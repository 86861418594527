import Storage from './storage'

class Session {
  constructor() {
    this.storage = new Storage();
  }

  getToken() {
    return this.storage.getItem('token') || '';
  }

  setToken(token) {
    if (!token) {
      return this.storage.removeItem('token');
    }
    return this.storage.setItem('token', token);
  }
}

export default Session;
