import React from 'react';
import './TableHeader.scss';

const TableHeader = (props) => {
  const { header } = props;
  const { name, isVisible} = header;
  
  const val = name.length ?  (<th className={isVisible ? 'is-visible': 'is-hidden' }>{name}</th>) : null;

  return val;
}

export default TableHeader;
