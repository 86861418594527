/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import './CardTitle.scss';

class CardTitle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowEdit: true,
      isEditing: false,
      newTitle: '',
    }
  }

  handleEdit = () => {
    this.setState({
      isShowEdit: true,
      isEditing: true,
    });
  };

  handleSave = () => {
    const { onTitleChange } = this.props;
    const { newTitle } = this.state;

    this.setState({
      isShowEdit: false,
    });

    onTitleChange(newTitle);
  };

  handleCancel = () => {
    this.setState({
      isShowEdit: false,
      newTitle: '',
    });
  };

  handleValueChange = (e) => {
    this.setState({
      isEditing: true,
      newTitle: e.target.value,
    })
  };

  render() {
    const {title} = this.props;
    const {isShowEdit, newTitle, isEditing} = this.state;

    return (
      <div className="card-title">
        {
          isShowEdit ? (
            <span>
              {
                isEditing ? (
                  <span>
                    <input
                        type="text"
                        className="form-control title-input"
                        onBlur={(e) => {this.handleSave(e)}}
                        onChange={this.handleValueChange}
                        value={newTitle}
                    />
                  </span>) : (
                  <span>
                    <span className="title">{title}</span>
                    <button type="button" className="btn btn-link" onClick={(e) => {this.handleEdit(e)}}>
                        <img src="/assets/pencil.svg" className="icon" alt="pencil"/>
                    </button>
                  </span>
                )
              }
            </span>) : (
            <span>
              {
                newTitle.length ? (
                  <span className="title">{newTitle}</span>
                ) : (
                  <span className="title">{title} </span>
                )
              }

              {
                isEditing ? (
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={(e) => {this.handleEdit(e)}}
                  >
                    <img src="/assets/pencil.svg" className="icon" alt="pencil"/>
                  </button>) : (
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={(e) => {this.handleSave(e)}}>
                      save
                  </button>
                )
              }
            </span>
          )
        }
      </div>
    );
  }
}

export default CardTitle;
