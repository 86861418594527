import React, { Component } from 'react';
import { Switch, Route, BrowserRouter as Router, Redirect } from 'react-router-dom';
import Session from './shared/services/session';
import ComponentPlayground from './features/comp-playground';
import NavSidebar from './shared/components/nav-sidebar/NavSidebar';
import DataHubContainer from './features/dataHub';
import Recommendations from './features/recommendations';
import RecommendationsEditorContainer from './features/recommendationsEditor';
import StaticResultContainer from './features/static-result';

import './App.scss';

class App extends Component {
  render() {
    return (
      <div className="App">
        <Router>
          <NavSidebar>
            <Switch>
              <Route exact path="/data-hub">
                <DataHubContainer />
              </Route> 
              <Route path="/recommendations/edit">
                <RecommendationsEditorContainer />
              </Route>
              <Route path="/recommendations/result">
                <StaticResultContainer />
              </Route>
              <Route path="/recommendations">
                <Recommendations />
              </Route>
              <Route path="/playground">
                <ComponentPlayground />
              </Route>
              <Redirect from="/" exact to="/data-hub" />
              <Route path="/">
                <DataHubContainer />
              </Route>
            </Switch>
          </NavSidebar>
        </Router>
      </div>
    )
  }
}

export default App;
