/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import './dataHubContainer.scss';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Session from '../../../shared/services/session';
import { getDatasets, uploadFiles } from '../actionCreators';
import FlightButton from '../../../shared/components/flight-button/FlightButton';
import TableList from '../../../shared/components/table-list/TableList';
import StateLabel from '../../../shared/components/state-label/StateLabel';
import StatusBar from '../../../shared/components/status-bar/StatusBar';
import SvgDelete from '../../../shared/icons/Delete';


const statusToStatesMap = {
  'Uploading': 'default',
  'Uploaded': 'info',
  'Training': 'info',
  'Training Failed': 'error',
  'Trained': 'success',
};

class DataHubContainer extends Component {
  constructor(props) {
    super(props);

    this.datasetsTableHeaders = [{
      name: '',
      value: 1,
      key: 'id',
      isVisible: false,
    }, {
      name: 'File Name',
      value: 1,
      key: 'Name',
      isVisible: true,
    }, {
      name: 'File Size',
      value: 1,
      key: 'Size',
      isVisible: true,
    }, {
      name: 'Date Uploaded',
      value: 1,
      key: 'CreatedAt',
      isVisible: true,
    }, {
      name: 'Date Trained',
      value: 1,
      key: 'ModifiedAt',
      isVisible: true,
    }, {
      name: 'Status',
      value: 1,
      key: 'Status',
      isVisible: true,
    }, {
      name: '',
      value: 1,
      key: 'Delete',
      isVisible: false,
    }];
  }

  componentDidMount() {
    if (window.location.href.includes('token')) {
      const session = new Session();      
      const url = new URL(window.location.href);
      const searchParams = new URLSearchParams(url.search);
      const token = searchParams.get('token');
      session.setToken(token);

      const { history } = this.props;
      history.push('/data-hub');
    }
  }

  onFilesAdded = (evt) => {
    const { files }  = evt.target;
    const { onUploadFiles } = this.props;
    const updatedFiles = [];
    const entries = this.transformFileName(files);

    for (const entry of entries) {
      updatedFiles.push(entry[1]);
    }

    const array = this.fileListToArray(updatedFiles);
    onUploadFiles(array);
  }

  handleRemoveUploadFile = (event, file) => {
      event.preventDefault();

      const { files } = this.state;
      const filteredFiles = files.filter((item) => {
          return item.name !== file.name;
      });

      this.setState({
          files: filteredFiles,
      });
  }

  handleDownloadSampleFile = () => {
    // TODO: Trigger download sample file
  }

  handleViewErrorClick = () => {
    // TODO: Trigger view error modal
  }

  handleDeleteUploadedFile = () => { // use (file) in args
    // TODO: delete action for file key
  }

  transformFileName = (files) => {
    const fd = new FormData();

    for (let i = 0; i < files.length; i++) {
      const now = Date.now();
      let name = files[i].name.split('.');
      const ext = name.pop();
      
      name = [`${name[0]}-${now + i}`];
      name.push(ext);

      const newFileName = name.join('.');

      fd.append('file[]', files[i], newFileName);
    }

    return fd.entries();
  }

  fileListToArray = (list) => {
    const array = [];

    for (let i = 0; i < list.length; i+=1) {
      array.push(list[i]);
    }

    return array;
  }

  render() {
    const { isDatasetsLoading, datasets, datasetsError } = this.props;
    if (datasetsError && datasetsError.length > 0) {
      return <div>{datasetsError}</div>;
    }

    const uploadEmptyMessage = (
      <span>
        Upload at least one file containing transaction data so the model
        can learn from your customers’ transaction history to provide product
        recommendations. Once data has been uploaded, training will start.
        <strong>Download sample file</strong> to get started.
      </span>
    );
    const datasetsTableData = datasets.map(data => {
      const deleteIcon = (
        <FlightButton
          type="minor"
          size="small"
          iconLeft={<SvgDelete />}
          className="table-item-delete"
          onClick={() => this.handleDeleteUploadedFile(data)}
        />
      );

      return { ...data,
        ModifiedAt: data.ModifiedAt || '--',
        Status: <StateLabel label={data.Status} type={statusToStatesMap[data.Status]} />,
        Delete: deleteIcon,};
    });
    const latestTrained = datasets.find(data => data.ModifiedAt);
    const statusBarElement = datasets.length > 0 && !isDatasetsLoading && latestTrained ? (
      <StatusBar type="info" label={`Model was last trained on ${
        latestTrained.ModifiedAt}.`} />
    ) : null;

    return (
      <div className="data-hub-container">
        {statusBarElement}
        <div className="data-hub-container__title">
          Datasets
          <div className="data-hub-container__title__upload">
            <input
              ref={ref => {this.fileInputRef = ref}}
              className="file-input"
              type="file"
              multiple
              accept="text/csv"
              onChange={this.onFilesAdded}
            />
            <FlightButton
              type="primary"
              label="New dataset"
              onClick={() => this.fileInputRef.click()}
            />
          </div></div>
        <a
          href="/assets/files/customers-sample.csv"
          download="customers-sample.csv"
          className="data-hub-container__sample-download"
        >
          <FlightButton
            type="link"
            label="Download sample file"
            onClick={() => undefined}
          />
        </a>
        <TableList
          className="upload-table"
          emptyStateMessage={uploadEmptyMessage}
          emptyStateImg='uploadFileEmpty'
          isLoading={isDatasetsLoading}
          tableData={datasetsTableData}
          tableHeaders={this.datasetsTableHeaders}
        />
      </div> 
    );
  }
}

DataHubContainer.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  // from mapStateToProps
  datasets: PropTypes.arrayOf(PropTypes.shape({
    Key: PropTypes.string,
    Name: PropTypes.string,
    Size: PropTypes.string,
    CreatedAt: PropTypes.string,
    ModifiedAt: PropTypes.string,
    Status: PropTypes.string,
    DownloadLink: PropTypes.string,
  })).isRequired,
  isDatasetsLoading: PropTypes.bool.isRequired,
  datasetsError: PropTypes.string,
};

DataHubContainer.defaultProps = {
  datasetsError: '',
}

const mapStateToProps = (state) => {
  return {
    datasets: state.DataHubDatasetsReducer.datasetsList,
    isDatasetsLoading: state.DataHubDatasetsReducer.isDatasetsLoading,
    datasetsError: state.DataHubDatasetsReducer.datasetsError,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onGetDatasets: dispatch(getDatasets()),
    onUploadFiles: (files => {
      dispatch(uploadFiles(files));
    })
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DataHubContainer));
