import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Session from '../../services/session';
import FlightButton from '../flight-button/FlightButton';
import { FLYBITS } from '../../../../constants';
import SvgArtificialIntelligence from '../../icons/ArtificialIntelligence';
import SvgDataConfiguration from '../../icons/DataConfiguration';
import SvgFlybitsLogo from '../../icons/FlybitsLogo';
import SvgProfileDefault from '../../icons/ProfileDefault';
import './NavSidebar.scss';


class NavSidebar extends Component {
  constructor(props) {
    super(props);
    this.routes = [
      {
        label: 'Data Hub',
        icon: <SvgDataConfiguration />,
        name: '/data-hub',
      },
      {
        label: 'Recommendations',
        icon: <SvgArtificialIntelligence />,
        name: '/recommendations',
      },
    ];

    this.state = {
      user: null,
    };
  }

  handleRouting(route) {
    const { history } = this.props;
    if (!route) {
      history.push('/');
      return;
    }

    history.push(route.name);
  }

  render() {
    const { user } = this.state;
    const { children, location } = this.props;

    return (
      <>
        <div className="nav-sidebar-wrapper">
          <div className="nav-sidebar">
            <div className="nav-sidebar-header">
              <SvgFlybitsLogo />
              <span className="nav-sidebar-header-title">Nestor</span>
            </div>
            <div className="nav-sidebar-content">
              {this.routes.map(route => (
                <FlightButton
                  label={route.label}
                  iconLeft={route.icon}
                  className={location.pathname.includes(route.name) ? 'selected' : ''}
                  size="large"
                  key={route.name}
                  type="secondary"
                  onClick={() => this.handleRouting(route)}
                />
              ))}
            </div>
            <div className="nav-sidebar-footer">
              <SvgProfileDefault />
              <div className="nav-sidebar-footer-user">
                <span className="nav-sidebar-footer-user-name">
                  Gerti Dervishi
                </span>
                <FlightButton
                  type="link"
                  label="Sign Out"
                  className="signout-btn"
                  onClick={() => undefined}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="route-content">
          {children}
        </div>
      </>
    );
  }
};

NavSidebar.propTypes = {
  children: PropTypes.element,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

NavSidebar.defaultProps = {
  children: null,
};

export default withRouter(NavSidebar);
