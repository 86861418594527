import API from '../../api/api';
import { GET_RESULTS_LOADING, GET_RESULTS_ERROR, GET_RESULTS_SUCCESS } from './types';
import Session from '../../shared/services/session';
import { NESTOR } from '../../../constants';
import axios from  '../../../utils/axios';

const getResultsLoading = (payload) => {
    return {
        type: GET_RESULTS_LOADING,
        payload,
    }
};

const getResultsError = (payload) => {
    return {
        type: GET_RESULTS_ERROR,
        payload,
    }
};

const getResultsSuccess = (payload) => {
    return {
        type: GET_RESULTS_SUCCESS,
        payload,
    }
};

const getResults = () => {
    getResultsLoading(true);
    return (data) => {
        const endpoint =  '/nestor/predictions';
        const options = {
            endpoint,
            body: data,
        };

        API.makePostRequest(options).then((response) => {
            getResultsSuccess(response)
            getResultsLoading(false)
        }).catch((response) => {
            getResultsError(response)
            getResultsLoading(false)
        });
    };
};

const sendRequest = async (file, fileType) => {
  const contentType = file.type || 'application/octet-stream';
  const session = new Session();
  const nestorDataHost = NESTOR.DATA_HOST;
  let subfolderName = '';

  if (fileType === 'productsFile') {
      subfolderName = 'item_list_data';
  }

  if (fileType === 'customersFile') {
      subfolderName = 'user_list_data';
  }

  const fileName = `${subfolderName}/${file.name}`;

  let response = await axios.get(`${nestorDataHost}/s3/presigned`, {
    headers: {
      Authorization: session.getToken(),
    },
    params: {
      name: fileName,
      contentType,
    }
  })

  const { url } = response.data;

  try {
    response = await axios.put(url, file, {
      headers: {
        'Content-Type': contentType,
      }
    });

    if (response) {
      // console.log('done!!');
    }
  } catch(error) {
    // if (error) {
    //   this.setState({
    //     error,
    //   })
    // }
  }
}

const uploadFiles = (file, fileType) => async (dispatch) => {
  try {
    await dispatch(sendRequest(file, fileType));
  } catch (e) {
    // console.log(e);
  } finally {
    // this.setState({
    //   // eslint-disable-next-line react/no-access-state-in-setstate
    //   ...this.state,
    //   file: null,
    // })
  }
}

export { getResults, uploadFiles };
