import React from 'react';
import PropTypes from 'prop-types';
import FlightButton from '../flight-button/FlightButton';

import './Modal.scss';

const Modal = (props) => {
  const { show, title, onClose, actionName, onContinue, children } = props

  return (
    <div className="modal-overlay" style={{
      transform: show ? 'translateY(0vh)': 'translateY(-100vh)',
    }}>
      <div className="modal-wrapper" style={{
        transform: show ? 'translateY(0vh)': 'translateY(-100vh)',
        opacity: show ? '1': '0',
        zIndex: 1,
      }}>
        <div className="modal-header">
          <h3 className="modal-title">{title}</h3>
        </div>
        <div className="modal-body">
          { children }
        </div>
        <div className="modal-footer">
          <FlightButton 
            label="Cancel"
            className="modal-footer__btn modal-footer__btn--cancel"
            type="minor"
            onClick={onClose}
          /> 
          <FlightButton 
            label={actionName}
            className="modal-footer__btn modal-footer__btn--continue"
            type="primary"
            onClick={onContinue}
          />
        </div>
      </div>
    </div>
  )
};

Modal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  actionName: PropTypes.string,
  onContinue: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.element,
};


Modal.defaultProps = {
  show: false,
  onClose: null,
  actionName: '',
  onContinue: null,
  title: '',
  children: null,
};

export default Modal;
