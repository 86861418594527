import React from 'react';
import PropTypes from 'prop-types';
import './StateLabel.scss';

const statesMap = {
  default: 'default',
  success: 'success',
  error: 'error',
  info: 'info',
};

const StateLabel = (props) => {
  const { label, type } = props;

  let stateLabelClass = 'state-label';
  stateLabelClass += (statesMap[type] ? ` ${stateLabelClass}--${statesMap[type]}` :
    ` ${stateLabelClass}--${statesMap.default}`);
  return (
    <span className={stateLabelClass}>
      {label}
    </span>
  );
};

StateLabel.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
}

StateLabel.defaultProps = {
  label: '',
  type: 'default',
}

export default StateLabel;
