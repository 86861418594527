import React, { Component } from 'react';
import './CompPlayground.scss';
import FlightButton from '../../../shared/components/flight-button/FlightButton';
import FlightRadioButton from '../../../shared/components/flight-radio-button/FlightRadioButton';
import FlightTextArea from '../../../shared/components/flight-text-area/FlightTextArea';
import FlightTextInput from '../../../shared/components/flight-text-input/FlightTextInput';
import FlightToggleSwitch from '../../../shared/components/flight-toggle-switch/FlightToggleSwitch';
import SvgAdd24 from '../../../shared/icons/Add24';
import SvgPerson24 from '../../../shared/icons/Person24';


class CompPlaygroundContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isFirstRadioSelected: true,
        disabled: false,
        toggleChecked: false,
      };

      this.setIsFirstRadioSelected = this.setIsFirstRadioSelected.bind(this);
      this.toggleDisabled = this.toggleDisabled.bind(this);
      this.toggleSwitchChecked = this.toggleSwitchChecked.bind(this);
    }

    setIsFirstRadioSelected(value) {
      this.setState({
        isFirstRadioSelected: value,
      });
    }

    toggleDisabled() {
      const { disabled } = this.state;
      this.setState({
        disabled: !disabled,
      });
    }

    toggleSwitchChecked(value) {
      this.setState({
        toggleChecked: value,
      });
    }

    render() {
      const { isFirstRadioSelected, disabled, toggleChecked } = this.state;
      return (
        <>
          <div className="block-sep">
            <FlightButton
              label="Action"
              iconLeft={<SvgAdd24 />}
              size="medium"
              type="secondary"
              disabled={false}
              loading
              onClick={this.toggleDisabled}
            />
          </div>
          <div className="block-sep">
            <FlightRadioButton
              label="RD1"
              checked={isFirstRadioSelected}
              disabled={disabled}
              onSelect={this.setIsFirstRadioSelected}
              value
            />
            <div className="inline-sep" />
            <FlightRadioButton
              label="RD2"
              checked={!isFirstRadioSelected}
              disabled={disabled}
              onSelect={this.setIsFirstRadioSelected}
              value={false}
            />
          </div>
          <div className="block-sep">
            <FlightTextInput
              labelText="Label"
              iconInput={<SvgPerson24 />}
              hasError={!isFirstRadioSelected}
              errorMessage="Required field"
              placeholderText="Some Text"
              width="232px"
              hasClearIcon
              value='123'
              disabled={disabled}
            />
          </div>
          <div className="block-sep">
            <FlightTextArea
              labelText="Label"
              hasError={!isFirstRadioSelected}
              errorMessage="Required field"
              disabled={disabled}
            />
          </div>
          <div className="block-sep">
            <FlightToggleSwitch
              labelText="Label"
              checked={toggleChecked}
              disabled={disabled}
              onChange={this.toggleSwitchChecked}
            />
            <div className="inline-sep" />
            {toggleChecked ? 'checked' : 'not checked'}
          </div>
        </>
      )
    }
}

export default CompPlaygroundContainer;