import React, { Component } from "react";
import PropTypes from 'prop-types';
import './FlightToggleSwitch.scss';

const DEFAULT_MAIN_CLASS = "flight-toggle-switch";
const KEYBOARD_ENTER_KEY = 'Enter';

class FlightToggleSwitch extends Component {
  handleClick = () => {
    const { disabled, onChange, checked } = this.props;
    if (disabled) return;
    if (onChange) {
      onChange(!checked);
    }
  }

  handleKeyPress = (event) => {
    if (event.key === KEYBOARD_ENTER_KEY) {
      this.handleClick();
    }
  }

  render() {
    const { disabled, labelText, checked, className } = this.props;

    let wrapperClass = `${DEFAULT_MAIN_CLASS}-wrapper`;
    wrapperClass += disabled ? ` ${wrapperClass}--disabled` : '';
    wrapperClass += className ? ` ${className}` : '';

    return (
      <div className={wrapperClass}>
        <div
          role="button"
          className={DEFAULT_MAIN_CLASS}
          tabIndex={disabled ? -1 : 0}
          onClick={this.handleClick}
          onKeyPress={this.handleKeyPress}
        >
          <label className={`${DEFAULT_MAIN_CLASS}__switch`} htmlFor="toggle-switch">
            <input
              className={`${DEFAULT_MAIN_CLASS}__switch__input`}
              type="checkbox"
              checked={checked}
              readOnly
            />
            <span className={`${DEFAULT_MAIN_CLASS}__switch__slide-bar`} />
            <div className={`${DEFAULT_MAIN_CLASS}__switch__slider`} />
          </label>
          <span className={`${DEFAULT_MAIN_CLASS}__span`}>{labelText}</span>
        </div>
      </div>

    );
  }
};

FlightToggleSwitch.propTypes = {
  labelText: PropTypes.string,
  checked: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

FlightToggleSwitch.defaultProps = {
  labelText: '',
  checked: false,
  className: '',
  disabled: false,
  onChange: null,
};

export default FlightToggleSwitch;
