import React, { Component } from "react";
import PropTypes from 'prop-types';
import './FlightRadioButton.scss';

const KEYBOARD_ENTER_KEY = 'Enter';

class FlightRadioButton extends Component {
  handleSelect = () => {
    const { disabled, onSelect, value } = this.props;
    if (disabled) return;
    if (onSelect) {
      onSelect(value);
    }
  }

  handleKeyPress = event => {
    if (event.key === KEYBOARD_ENTER_KEY) {
      this.handleSelect();
    }
  }

  render() {
    const { disabled, checked, label, className } = this.props;
    const mainClass = 'flight-radio-button';
    const labelClass = `${mainClass}__span`;
    const labelElement = label ? <span className={labelClass}>{label}</span> : null;

    let wrapperClass = `${mainClass}-wrapper`;
    wrapperClass += disabled ? ` ${wrapperClass}--disabled` : '';
    wrapperClass += className ? ` ${className}` : '';

    let radioClass = `${mainClass}__radio`;
    radioClass += checked ? ` ${radioClass}--selected` : '';

    return (
      <div className={wrapperClass}>
        <div
          className={mainClass}
          role="button"
          tabIndex={disabled ? -1 : 0}
          onClick={this.handleSelect}
          onKeyPress={this.handleKeyPress}
        >
          <div className={radioClass} />
          {labelElement}
        </div>
      </div>

    );
  }
};

FlightRadioButton.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onSelect: PropTypes.func,
  /* eslint-disable react/forbid-prop-types */
  value: PropTypes.any,
};

FlightRadioButton.defaultProps = {
  label: '',
  checked: false,
  className: '',
  disabled: false,
  onSelect: null,
  value: null,
};

export default FlightRadioButton;
