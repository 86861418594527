export const ICONS = {
  add: 'add-24',
  baseArrowDown: 'baseline-arrow-down-24',
  baseArrowLeft: 'baseline-arrow-left-24',
  baseArrowRight: 'baseline-arrow-right-24',
  baseArrowUp: 'baseline-arrow-up-24',
  baseCheck: 'baseline-check-24',
  baseKeyArrowDown: 'baseline-keyboard-arrow-down-24',
  baseKeyArrowLeft: 'baseline-keyboard-arrow-left-24',
  baseKeyArrowRight: 'baseline-keyboard-arrow-right-24',
  baseKeyArrowUp: 'baseline-keyboard-arrow-up-24',
  bell: 'bell-24px',
  bellAlarm: 'bell-alarm-24px',
  checkCircle: 'check-circle-24',
  checkBoxCheck: 'checkbox-check-24',
  clear: 'clear-24',
  clockFill: 'clock-fill-24',
  cloudFail: 'cloud-failed-24px',
  cog: 'cog-24',
  collection: 'collection-24px',
  counter: 'counter-24',
  downCarrot: 'down-carrot-24',
  download: 'download-24px',
  error: 'error-24',
  helpOutline: 'help-outline-24px',
  indeterCheckbox: 'indeterminate-checkbox-24',
  info: 'info-24',
  infoStroke: 'info-stroke-24',
  leftCarrot: 'left-carrot-24',
  loading: 'loading-24',
  mathDiv: 'math-divide-24',
  mathExp: 'math-exponent-24',
  mathMin: 'math-minus-24',
  mathMod: 'math-modulo-24',
  mathMulti: 'math-multiply-24',
  mathPlus: 'math-plus-24',
  menu: 'menu-24px',
  pencil: 'pencil-24',
  people: 'people-24',
  person: 'person-24',
  personAdd: 'person-add-24',
  remove: 'remove-24',
  rightCarrot: 'right-carrot-24',
  search: 'search-24',
  subject: 'subject-24px',
  tag: 'tag-24',
  target: 'target-24px',
  upCarrot: 'up-carrot-24',
  warning: 'warning-24',
  ai: 'artificial-intelligence',
  dataConfig: 'data-configuration',
  logo: 'flybits-logo',
  profile: 'profile-default',
  delete: 'delete',
};

export const EMPTY_STATES = {
  recommendationEmpty: 'recommendations-empty-state',
  uploadFileEmpty: 'upload-file-empty-state',
  trainingHistoryEmpty: 'training-history-empty-state',
};

export const CHECKBOX_STATES = {
  UNSELECTED: 'UNSELECTED',
  INDETERMINATE: 'INDETERMINATE',
  SELECTED: 'SELECTED',
};
