/* eslint-disable class-methods-use-this */
class Storage {
  clear() {
    localStorage.clear();
  }

  getItem(key) {
    return window.localStorage.getItem(key);
  }

  removeItem(key) {
    window.localStorage.removeItem(key);
  }

  setItem(key, value) {
    window.localStorage.setItem(key, value);
  }

  setItems(object) {
    Object.keys(object).forEach(key => {
      if (Object.prototype.hasOwnProperty.call(object, key)) {
        let value = null;

        if (object[key]) {
          value = object[key];
        }

        this.setItem(key, value);
      }
    });
  }
}

export default Storage;
