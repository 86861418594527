import { ICONS, EMPTY_STATES, CHECKBOX_STATES } from './designSystem';

export const FLYBITS = {
  FLYBITS_HOST: process.env.REACT_APP_FLYBITS_HOST,
  FLYBITS_HOST_TOKEN: process.env.REACT_APP_FLYBITS_HOST_TOKEN,
}

export const NESTOR = {
  DATA_HOST: process.env.REACT_APP_NESTOR_DATA_HOST,
}

export const STORE_STATE = {
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

export const DESIGN_SYSTEM = {
  ICONS,
  EMPTY_STATES,
  CHECKBOX_STATES,
  STORE_STATE,
}
