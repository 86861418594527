import * as types from './types';

const initialState = {
  datasetsList: [],
  isDatasetsLoading: false,
  datasetsError: '',
};

export const DataHubDatasetsReducer = (state = initialState, action) => {
  switch(action.type) {
    case types.DATA_HUB_DATASETS_LOADING:
      return { ...state, isDatasetsLoading: action.payload };
    case types.DATA_HUB_DATASETS_SUCCESS:
      return { ...state, isDatasetsLoading: false, datasetsList: action.payload };
    case types.DATA_HUB_DATASETS_ERROR:
      return { ...state, isDatasetsLoading: false, datasetsError: action.payload };
    default:
      return state;
  }
}
