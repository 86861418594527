import React from 'react';

const SvgFlybitsLogo = props => (
  <svg width={37} height={38} fill="none" {...props}>
    <path
      d="M18.474 22.082c1.781 0 3.226-1.47 3.226-3.283s-1.445-3.282-3.226-3.282c-1.782 0-3.226 1.47-3.226 3.282 0 1.814 1.444 3.283 3.226 3.283zM5.464 10.996c-.952.107-5.183.7-5.447 1.614-.265.861 2.697 3.875 3.437 4.575.212-2.26.9-4.36 2.01-6.19zM10.805 32.038c.106.968.688 5.274 1.587 5.543.846.269 3.808-2.745 4.495-3.498-2.221-.216-4.284-.915-6.082-2.045zM33.546 20.413c-.211 2.26-.952 4.359-2.01 6.189.952-.108 5.183-.7 5.448-1.615.264-.86-2.75-3.874-3.438-4.574zM26.195 5.56c-.106-.969-.688-5.274-1.587-5.543-.846-.27-3.86 2.745-4.495 3.498 2.169.215 4.231.915 6.082 2.045z"
      fill="#051132"
    />
    <path
      d="M29.527 13.794c-2.698-6.243-9.89-8.988-15.972-6.243-6.082 2.745-8.832 10.064-6.134 16.253 2.697 6.189 9.89 8.987 15.971 6.242 6.135-2.744 8.885-10.063 6.135-16.252zm-7.774 12.54c-4.073 1.829-8.832 0-10.63-4.145-1.799-4.144 0-8.987 4.072-10.817s8.832 0 10.63 4.144c1.85 4.09 0 8.934-4.072 10.817z"
      fill="#04BCF8"
    />
  </svg>
);

export default SvgFlybitsLogo;
