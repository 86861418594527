import * as types from './types';

const getRecommendationsLoading = (payload) => {
    return {
        type: types.RECOMMENDATIONS_LOADING,
        payload
    }
};

const getRecommendationsSuccess = (payload) => {
    return {
        type: types.RECOMMENDATIONS_SUCCESS,
        payload
    }
};

const getRecommendationsError = (payload) => {
    return {
        type: types.RECOMMENDATIONS_ERROR,
        payload
    }
};

const fetchRecommendations = () => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({
                data: [
                          {
                              Key: '1',
                              Name: 'November Recommendations',
                              CreatedAt: 'Nov 1, 2019',
                              Status: 'Completed',
                              DownloadLink: '',
                          },
                          {
                              Key: '2',
                              Name: 'October Recommendations',
                              CreatedAt: 'Oct 2, 2019',
                              Status: 'Completed',
                              DownloadLink: '',
                          },
                          {
                              Key: '3',
                              Name: 'September Recommendations',
                              CreatedAt: 'Sept 3, 2019',
                              Status: 'Completed',
                              DownloadLink: '',
                          },
                          {
                              Key: '4',
                              Name: 'August Recommendations',
                              CreatedAt: 'Aug 1, 2019',
                              Status: 'Completed',
                              DownloadLink: '',
                          },
                          {
                              Key: '5',
                              Name: 'July Recommendations',
                              CreatedAt: 'Jul 3, 2019',
                              Status: 'Completed',
                              DownloadLink: '',
                          },
                          {
                              Key: '6',
                              Name: 'June Recommendations',
                              CreatedAt: 'Jun 1, 2019',
                              Status: 'Completed',
                              DownloadLink: '',
                          },
                          {
                              Key: '7',
                              Name: 'May Recommendations',
                              CreatedAt: 'May 1, 2019',
                              Status: 'Completed',
                              DownloadLink: '',
                          },
                          {
                              Key: '8',
                              Name: 'April Recommendations',
                              CreatedAt: 'Apr 1, 2019',
                              Status: 'Completed',
                              DownloadLink: '',
                          },
                          {
                              Key: '9',
                              Name: 'March Recommendations',
                              CreatedAt: 'Mar 1, 2019',
                              Status: 'Completed',
                              DownloadLink: '',
                          },
                          {
                              Key: '10',
                              Name: 'Feb Recommendations',
                              CreatedAt: 'Feb 1, 2019',
                              Status: 'Completed',
                              DownloadLink: '',
                          }
                ]
            })
        }, 1000);
    });
};

const getRecommendations = () => (dispatch) => {
    const apiCall = fetchRecommendations();
    let isLoading = true;

    dispatch(getRecommendationsLoading(isLoading));
    apiCall
    .then(res => {
      return res;
    })
    .then(response => {
        isLoading = false;
        dispatch(getRecommendationsLoading(isLoading));
        dispatch(getRecommendationsSuccess(response.data));
    })
    .catch(err => {
        isLoading = false;
        dispatch(getRecommendationsLoading(isLoading));
        dispatch(getRecommendationsError(err))
    });
};

export { getRecommendations as default };
