import Session from '../shared/services/session';
import { NESTOR } from '../../constants';

const getHeaders = () => {
    const session = new Session();
    const jwt = session.getToken()

    return {
        'X-Authorization': jwt,
        'Content-Type': 'application/json',
    }
};

const successfulRequest = (data) => {
    return data;
}

const failedRequest = (data) => {
    return data;
}

const makeAPIRequest = (options, thenCallback, catchCallback) => {
    const url = `${NESTOR.DATA_HOST}${options.endpoint}`;

    const obj = options;
    delete obj.endpoint;
    obj.headers = getHeaders();

    return new Promise((resolve) => {
        fetch(url, obj)
            .then(response => response.json())
            .then(response => resolve(thenCallback(response)))
            .catch(response => resolve(catchCallback(response)))
    });
}

const makeGetRequest = (data) => {    
    const options = { ...data, 
        endpoint: data.endpoint,
        host: data.host,
        method: 'GET',
        queryParamas: data.queryParamas || {},
    };

    return makeAPIRequest(options, successfulRequest, failedRequest)
};

const makeGetRequestById = (data) => {
    const options = { ...data, 
        endpoint: `${data.endpoint}/${data.id}`,
        method: 'GET',
        queryParamas: data.queryParamas || {},
    };

    return makeAPIRequest(options, successfulRequest, failedRequest)
};
const makePostRequest = (data) => {
    const options = { ...data, 
        method: 'POST',
        body: JSON.stringify(data.body),
    };

    return makeAPIRequest(options, successfulRequest, failedRequest);
};

const makePutRequest = (data) => {
    const options = { ...data, 
        endpoint: data.endpoint,
        method: 'PUT',
        queryParamas: data.queryParamas || {},
    };

    return makeAPIRequest(options, successfulRequest, failedRequest);
};

const makeDeleteRequest = (data) => {
    const options = { ...data,
        endpoint: `${data.endpoint}/${data.id}`,
        method: 'DELETE',
        queryParamas: data.queryParamas || {},
    };

    return makeAPIRequest(options, successfulRequest, failedRequest);
};

const API = {
    makeGetRequestById,
    makeGetRequest,
    makePostRequest,
    makePutRequest,
    makeDeleteRequest,
};

export default API;
