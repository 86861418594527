import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './TableLoading.scss';
import Grid from  '../../../../utils/grid';

class TableLoading extends Component {
  render() {
    const { rowNumber, colNumber } = this.props;
    const grid = new Grid(rowNumber, colNumber);

    let rowKey = 0;
    let cellKey = 0;

    return (
      grid.content.map(row => {
        rowKey += 1;
        return (
          <tr key={`loading-row${rowKey}`}>
            {
              row.map(() => {
                cellKey += 1;
                return (
                  <td className="table-placeholder-cell" key={`loading-cell${cellKey}`}>
                    <div className="table-loading-bar" />
                  </td>
                );
              })
            }
          </tr>
        );
      })
    );
  }
};

TableLoading.propTypes = {
  rowNumber: PropTypes.number,
  colNumber: PropTypes.number.isRequired,
};

TableLoading.defaultProps = {
  rowNumber: 3,
};

export default TableLoading;
